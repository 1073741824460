.blog-container {
    padding: 40px;
    display: flex; /* Add this line */
    flex-direction: column; /* Add this line */
  }
  
  .blog-posts-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .blog-post-tile {
    padding: 20px;
    background-color: #5d5d5d;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
  }
  
  .blog-post-tile:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  h1 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
    
  }
  
  h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 18px;
    color: #555555;
  }
  
  .description {
    font-size: 16px;
    color: #777777;
    margin-bottom: 10px;
  }
  
  .tags {
    margin-bottom: 10px;
  }
  
  .tag {
    display: inline-block;
    padding: 4px 8px;
    background-color: #5e5e5e;
    color: #333333;
    border-radius: 4px;
    margin-right: 5px;
  }
  
  .blog-3d-container {
    width: 100%; /* Set the width to 100% to occupy the available space */
    height: 700px; /* Adjust the height as needed */
  }
  
  .text-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }