// Sets variant colors for bootstrap to use
$theme-colors: (
    "primary": #030d17,//#000F08,
    "primary-secondary": #5a5a5a,
    "secondary": #818181,
    "tertiary":rgba(184, 184, 255, 0.7),

    "neutral": #1D2951,
    "accent": #008F8A,

    "light": rgba(255, 252, 252, 0.7),
);
// Sets the font family variables
:root {
    --font-family-sans-serif: 'Roboto', sans-serif;
    --font-family-monospace: 'Roboto Mono', monospace;
    --font-family-serif: 'Roboto Serif', serif;
}
html, body { height: 100%; color: rgba(255, 252, 252, 0.7); }

.main-title{
}

.jumbotron{
  margin:auto;
  width:100%;
  padding:200px 50px;
}
.jumbotron > h6{
    margin-top:50px;
}
.jumbotron > Button{
    margin-top:50px;
}
.main-card{
    width:80vw;
    margin:auto;
    margin-top:100px;
}
.main-card > img {
    height:400px;
    width:100%;
    border: 1px solid black;
    border-radius:20px;
    object-fit: cover;
}
.main-card > h4 {
    width:90%;
    margin-left:10px;
    font-size: 2ch;
    margin-top:10px;
}
.main-card > h1 {
    font-family: "Roboto", sans-serif;
    margin-left:10px;
}

.f2{
    margin-top:50px;
    bottom:0;
    padding:10px 10px 0 10px;
}
.card-buttons{
    padding: 10px 0;
    display:flex;
    justify-content: space-around;
}
.modal-background{
    width:100vw;
    height:100vh;
    background-color:rgb(0,0,0,.6);
    position:fixed;
    top:0;
}

.modal-vid{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}
.p404{
    width:100%;
    text-align:center;
    padding:100px;
}
.nav{
    color:white;
}

.h1{
    color: rgb(198, 197, 197);
}


@media only screen and (min-width: 1200px) {
    .main-card{
        width:50vw;
    }

    .jumbotron{
      padding:150px 20px;
      margin:auto;
        width:50vw;
    }

}

// Imports bootstrap
@import "~bootstrap/scss/bootstrap";