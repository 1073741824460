.about-us-wrapper .about-us-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
    background-color: #0e3a49;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
  
  .about-us-wrapper .about-us-content {
    flex: 1;
    margin-right: 40px;
    color: white; /* Update text color to white */
  }
  
  .about-us-wrapper .about-us-3d-container {
    flex: 1;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 40px;
  }
  
  .about-us-wrapper h1 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 30px;
    color: white; /* Update text color to white */
  }
  
  .about-us-wrapper p {
    font-size: 18px;
    margin-bottom: 20px;
    color: white; /* Update text color to white */
  }
  
  @media (max-width: 768px) {
    .about-us-wrapper .about-us-container {
      flex-direction: column;
      align-items: center;
      padding: 20px;
    }
  
    .about-us-wrapper .about-us-content,
    .about-us-wrapper .about-us-3d-container {
      margin: 20px 0;
    }
  
    .about-us-wrapper h1 {
      font-size: 28px;
    }
  
    .about-us-wrapper p {
      font-size: 16px;
    }
  }
  