// Imports custom.scss :/
@import "custom";

// font-family: 'Roboto', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

// font-family: 'Roboto Mono', monospace;
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

// font-family: 'Roboto Serif', serif;
@import url('https://fonts.googleapis.com/css2?family=Roboto+Serif:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html, body, #root {
  height: 100%;
}

h1 {
  font-family: var(--font-family-serif);
}

/*h1 {
  font-family: 'Roboto Serif', serif;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.2;
  margin: 0;
  padding: 0;
}

p {
  font-family: 'Roboto', sans-serif;
  font-size: 1.5rem;
  line-height: 1.5;
  color: #000;
}*/

// create-react-app default styles
// * Will probably be deleted in the future *
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgba(184, 184, 255, 0.7);
}

.App-link {
  color: #61dafb;
}

/* Footer.css */
.footer {
  background-color: #597692; /* Adjust the background color as needed */
  padding: 200px 0;
  position: fixed;
  bottom: 0;
  width: 100%;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
