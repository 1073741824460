/* Demo.css */

.chat-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
    background-color: #000000;
    padding: 40px;
    box-sizing: border-box;
  }
  
  .video-player {
    width: 100%;
    max-width: 800px;
    height: 400px;
    border-radius: 12px;
    margin-bottom: 40px;
    position: relative; /* Add this line */
    overflow: hidden
  }

  .video-player:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid white;
    border-radius: 50%;
    box-sizing: border-box;
  }

  .tile-box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 12px;
    background-color: #f2f2f2;
    opacity: 0.9;
    z-index: 1;
  }
  
  .conversation-window {
    width: 100%;
    max-width: 800px;
    max-height: 400px;
    overflow-y: auto;
    background-color: #1e1e1e;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
  }
  
  .chat-message {
    font-size: 18px;
    margin-bottom: 16px;
    padding: 12px 20px;
    border-radius: 12px;
    color: #fff;
    background-color: #333333;
    max-width: 70%;
    align-self: flex-start;
    animation: fadeIn 0.3s ease forwards;
  }
  
  .chat-message.user {
    background-color: #007bff;
    align-self: flex-end;
    animation: fadeIn 0.3s ease forwards;
  }
  
  .chat-form {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 800px;
  }
  
  .chat-input {
    flex-grow: 1;
    padding: 12px;
    border: none;
    border-radius: 12px;
    outline: none;
    font-size: 18px;
    background-color: #333333;
    color: #fff;
  }
  
  .chat-submit-button {
    padding: 12px 20px;
    margin-left: 12px;
    border: none;
    border-radius: 12px;
    background-color: #007bff;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
  }
  
  .chat-submit-button:hover {
    background-color: #0056b3;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Add the following CSS */
  
  .chat-messages {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .chat-message.user {
    align-self: flex-start;
  }
  
  .chat-message.character {
    align-self: flex-end;
  }
  
  .disclaimer {
    margin-top: 10px;
  }
  
  .disclaimer-text {
    color: red;
  }
  