.learn-more-button,
.demo-button {
  background-color: transparent;
  border: 2px solid #CCC;
  color: #CCC;
  padding: 10px 20px;
  cursor: pointer;
  text-decoration: none; /* Add this line to remove the underline */
}
  
  .main-card .demo-button:hover,
  .main-card .learn-more-button:hover {
    background-color: #CCC;
    color: #FFF;
  }
  
  .home-container {
    /* Your existing styles for the home container */
    padding-bottom: 50px; /* Add the desired amount of padding at the bottom */
  }
  