/* UniversalAiTutor.css */

.universal-ai-tutor {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
}

.universal-ai-tutor__content {
  max-width: 1200px;
  padding: 20px; /* Reduced padding for smaller screens */
  background-color: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  color: #333333; /* Added font color for readability */
}

.universal-ai-tutor__hero {
  text-align: center;
  margin-bottom: 20px; /* Reduced margin for smaller screens */
}

.universal-ai-tutor__hero-title {
  font-size: 24px; /* Reduced font size for smaller screens */
  font-weight: bold;
  color: #333333;
}

.universal-ai-tutor__hero-subtitle {
  font-size: 16px; /* Reduced font size for smaller screens */
  color: #666666;
}

.universal-ai-tutor__features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px; /* Reduced grid gap for smaller screens */
  margin-bottom: 20px; /* Reduced margin for smaller screens */
}

.universal-ai-tutor__feature-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.universal-ai-tutor__feature-icon {
  width: 150px; /* Reduced icon size for smaller screens */
  height: 150px;
  margin-bottom: 10px; /* Reduced margin for smaller screens */
}

.universal-ai-tutor__feature-title {
  font-size: 18px; /* Reduced font size for smaller screens */
  font-weight: bold;
  color: #333333;
  margin-bottom: 5px; /* Reduced margin for smaller screens */
}

.universal-ai-tutor__feature-description {
  font-size: 14px; /* Reduced font size for smaller screens */
  color: #666666;
}

.universal-ai-tutor__testimonial {
  text-align: center;
  margin-bottom: 20px; /* Reduced margin for smaller screens */
}

.universal-ai-tutor__testimonial-quote {
  font-size: 16px; /* Reduced font size for smaller screens */
  font-style: italic;
  color: #666666;
  margin-bottom: 5px; /* Reduced margin for smaller screens */
}

.universal-ai-tutor__testimonial-author {
  font-size: 12px; /* Reduced font size for smaller screens */
  color: #999999;
}

.universal-ai-tutor__call-to-action {
  text-align: center;
  margin-bottom: 20px; /* Reduced margin for smaller screens */
}

.universal-ai-tutor__cta-title {
  font-size: 18px; /* Reduced font size for smaller screens */
  font-weight: bold;
  color: #333333;
  margin-bottom: 5px; /* Reduced margin for smaller screens */
}

.universal-ai-tutor__cta-description {
  font-size: 14px; /* Reduced font size for smaller screens */
  color: #666666;
  margin-bottom: 10px; /* Reduced margin for smaller screens */
}

.universal-ai-tutor__cta-button {
  padding: 8px 16px; /* Reduced padding for smaller screens */
  font-size: 16px; /* Reduced font size for smaller screens */
  font-weight: bold;
  color: #ffffff;
  background-color: #ff6b6b;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.universal-ai-tutor__cta-button:hover {
  background-color: #ff4f4f;
}

/* Media queries for responsiveness */
@media screen and (max-width: 768px) {
  .universal-ai-tutor__hero-title {
    font-size: 22px;
  }

  .universal-ai-tutor__hero-subtitle {
    font-size: 14px;
  }

  .universal-ai-tutor__feature-title {
    font-size: 16px;
  }

  .universal-ai-tutor__feature-description {
    font-size: 12px;
  }

  .universal-ai-tutor__testimonial-quote {
    font-size: 14px;
  }

  .universal-ai-tutor__testimonial-author {
    font-size: 10px;
  }

  .universal-ai-tutor__cta-title {
    font-size: 16px;
  }

  .universal-ai-tutor__cta-description {
    font-size: 12px;
  }

  .universal-ai-tutor__cta-button {
    font-size: 14px;
    padding: 6px 12px;
  }
}
