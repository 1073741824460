.tutorials-container {
    padding: 40px;
  }
  
  .tutorials-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .tutorial-tile {
    padding: 20px;
    background-color: #abb6b9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-height: 150px;
    transition: max-height 0.3s ease;
    /* Add the following styles for smooth transitions */
    overflow: hidden;
    transition: max-height
  }

  .tutorial-tile.expanded {
    max-height: 1000px;
  }
  
  .tutorial-tile:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .tutorials-container h1 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
    color:#e4dddd;
  }
  
  .tutorials-container h3 {
    font-size: 24px;
    margin-bottom: 10px;
    color:#3d3434;
  }
  
  .tutorials-container p {
    font-size: 18px;
    color: #3d3434;
  }

  .section-gap {
    margin-bottom: 40px;
  }

  .expanded {
    height: auto !important;
  }
  

  .tutorial-details-wrapper {
    opacity: 0; /* Start with 0 opacity */
    max-height: 0; /* Start with 0 height */
    overflow: hidden;
    transition: opacity 0.3s ease, max-height 0.3s ease; /* Transition properties */
  }
  
  .tutorial-tile.expanded .tutorial-details-wrapper {
    opacity: 1; /* Expand the opacity */
    max-height: 1000px; /* Expand the height */
  }
  